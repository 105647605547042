// Function to convert IRentIncreaseTableForm to RentReviewTableValue
import type {
  IRentIncreaseTableForm,
  RentReviewTableValue as RentReviewTableValueTable,
} from '@register/components/Review/Form/RentReviewTable'
import type { RentReviewTable } from '@manager/components/Group/Custom/RentReviewTable/types'
import type { OptionRentReviewTable } from '@manager/components/Group/Custom/OptionRentIncreaseTable/types'
import { IncreaseTableMethod } from '@register'

export function convertToRentReviewAPI(
  form: IRentIncreaseTableForm,
): RentReviewTable {
  const data: RentReviewTable = {
    Date: form.date,
    Method: form.method ? convertMethodToAPI(form.method) : undefined,
    FixedPercent: form.fixedPercent,
    FixedAmount: form.fixedAmount,
    CpiPercent: form.cpiPercent,
    CpiCap: form.cpiCap,
    CpiCollar: form.cpiCollar,
    MarketReviewDate: form.marketReviewDate,
    MarketReviewCap: form.marketReviewCap,
    MarketReviewCollar: form.marketReviewCollar,
    GreaterOf: form.greaterOf,
    LesserOf: form.lesserOf,
    Formula: form.formula,
  } as RentReviewTable

  Object.defineProperty(data, '__duplicate', {
    enumerable: false,
    value: form.duplicate,
  })

  return data
}

// Function to convert RentReviewTableValue to IRentIncreaseTableForm
export function convertToIRentIncreaseTableForm(
  value: RentReviewTable,
): IRentIncreaseTableForm {
  return {
    date: value.Date ?? undefined,
    method: value.Method ? convertMethodToTable(value.Method) : undefined,
    fixedPercent: value.FixedPercent ?? undefined,
    fixedAmount: value.FixedAmount ?? undefined,
    cpiPercent: value.CpiPercent ?? undefined,
    cpiCap: value.CpiCap ?? undefined,
    cpiCollar: value.CpiCollar ?? undefined,
    marketReviewDate: value.MarketReviewDate ?? undefined,
    marketReviewCap: value.MarketReviewCap ?? undefined,
    marketReviewCollar: value.MarketReviewCollar ?? undefined,
    greaterOf: value.GreaterOf ?? undefined,
    lesserOf: value.LesserOf ?? undefined,
    formula: value.Formula ?? undefined,
    duplicate: value.__duplicate,
  }
}

export function convertToRentReviewTable(
  value: RentReviewTable | OptionRentReviewTable,
): RentReviewTableValueTable {
  const data = {
    option: (value.Option as string | number | null) ?? undefined,
    date: value.Date ?? undefined,
    method: value.Method ? convertMethodToTable(value.Method) : undefined,
    fixedPercent: value.FixedPercent ?? undefined,
    fixedAmount: value.FixedAmount ?? undefined,
    cpiPercent: value.CpiPercent ?? undefined,
    cpiCap: value.CpiCap ?? undefined,
    cpiCollar: value.CpiCollar ?? undefined,
    marketReviewDate: value.MarketReviewDate ?? undefined,
    marketReviewCap: value.MarketReviewCap ?? undefined,
    marketReviewCollar: value.MarketReviewCollar ?? undefined,
    greaterOf: value.GreaterOf ?? undefined,
    lesserOf: value.LesserOf ?? undefined,
    formula: value.Formula ?? undefined,
    duplicate: value.__duplicate,
  }

  Object.defineProperty(data, '__id', {
    enumerable: false,
    value: value.__id,
  })

  return data
}

export function convertMethodToAPI(method: IncreaseTableMethod) {
  switch (method) {
    case IncreaseTableMethod.FIXED_AMOUNT:
      return 'FixAmount'
    case IncreaseTableMethod.FIXED_PERCENT:
      return 'FixPercentage'
    case IncreaseTableMethod.CPI:
      return 'Cpi'
    case IncreaseTableMethod.CPI_PLUS:
      return 'CpiPlus'
    case IncreaseTableMethod.MARKET:
      return 'Market'
  }
}
export function convertMethodToTable(method: string) {
  switch (method) {
    case 'FixAmount':
      return IncreaseTableMethod.FIXED_AMOUNT
    case 'FixPercentage':
      return IncreaseTableMethod.FIXED_PERCENT
    case 'Cpi':
      return IncreaseTableMethod.CPI
    case 'CpiPlus':
      return IncreaseTableMethod.CPI_PLUS
    case 'Market':
      return IncreaseTableMethod.MARKET
  }
}
